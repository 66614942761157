import { gsap, ScrollTrigger } from "gsap/all";

gsap.registerPlugin(ScrollTrigger);

const applyHighlightAnimation = (
  element,
  color,
  duration = 0.75,
  ease = "power1.in"
) => {
  const tween = gsap.fromTo(
    element,
    {
      background: `linear-gradient(to right, ${color} 0%, transparent 0%)`,
    },
    {
      background: `linear-gradient(to right, ${color} 100%, transparent 0%)`,
      duration: duration,
      ease: ease,
    }
  );
  tween.play();
  return tween;
};

const applyFadeInAnimation = ref => {
  const timeline = gsap
    .timeline()
    .fromTo(
      ref.current,
      { opacity: 0, y: 80 },
      { opacity: 1.0, y: 0, duration: 0.35, ease: "power1.in" }
    );

  return {
    timeline,
    scrollTriggerInstance: ScrollTrigger.create({
      animation: timeline,
      trigger: ref.current,
      start: "top 100%-=112",
    }),
  };
};

const applyParallaxPhoto = (containerEl, imageEl) => {
  const containerH = containerEl.offsetHeight;
  const imageH = imageEl.offsetHeight;

  // animation timeline with scrolltrigger config
  const animationTimeline = gsap.timeline();

  // animation tween
  animationTimeline.to(imageEl, { y: containerH - imageH });

  return {
    timeline: animationTimeline,
    scrollTriggerInstance: ScrollTrigger.create({
      animation: animationTimeline,
      trigger: containerEl,
      start: "top bottom",
      end: "bottom top",
      scrub: true,
      ease: "circ.inOut",
    }),
  };
};

const textTween = (
  textEl,
  { fadeIn = true, pause = 0.5, fadeOut = true } = {}
) => {
  const masterTimeline = gsap.timeline();

  const fadeInTimeline = gsap
    .timeline()
    .fromTo(
      textEl,
      { opacity: 0 },
      { opacity: 1.0, duration: 0.125, ease: "power1.in" }
    )
    .fromTo(textEl, { y: 32 }, { y: 0, duration: 0.25, ease: "power1.in" }, "<")
    .to({}, { duration: pause });

  const fadeOutTimeline = gsap
    .timeline()
    .fromTo(textEl, { y: 0 }, { y: -16, duration: 0.125, ease: "power1.out" })
    .fromTo(
      textEl,
      { opacity: 1.0 },
      { opacity: 0, duration: 0.125, ease: "power1.out" },
      ">-0.125"
    );

  // fade in by default
  if (fadeIn === true) {
    masterTimeline.add(fadeInTimeline);
  }

  // allow opt out of fade out animation
  if (fadeOut === true) {
    masterTimeline.add(fadeOutTimeline);
  }

  return masterTimeline;
};

const getSequenceTween = (
  { canvasRef, duration, frameOptions, renderFirstFrame = true, ease = "none" },
  callback
) => {
  const framePosition = { frame: 0 };
  const imageFrames = [];
  const { srcPrefix, frameCount, width, height } = frameOptions;

  const renderFrame = ({ canvasRef, imageFrames, framePosition }) => {
    const canvas = canvasRef.current;
    if (canvas) {
      const activeImage = imageFrames[framePosition.frame];
      const renderContext = canvas.getContext("2d");
      renderContext.clearRect(0, 0, canvas.width, canvas.height);
      renderContext.drawImage(activeImage, 0, 0);
    }
  };

  (function preloadFrames(callback) {
    let loadedImageCount = 0;

    const imageLoaded = () => {
      loadedImageCount++;
      if (typeof callback === "function" && loadedImageCount >= frameCount) {
        callback();
      }
    };

    const getImageSrc = index =>
      `${srcPrefix}${index.toString().padStart(4, "0")}.jpg`;

    for (let i = 0; i < frameCount; i += 1) {
      const image = new Image();
      image.onload = imageLoaded();
      image.src = getImageSrc(i);
      imageFrames.push(image);
    }

    // render first frame by default
    if (renderFirstFrame) {
      imageFrames[0].onload = () => {
        renderFrame({
          canvasRef,
          imageFrames,
          framePosition,
        });
      };
    }
  })(callback);

  (function setupCanvases() {
    canvasRef.current.width = width;
    canvasRef.current.height = height;
  })();

  return gsap.to(framePosition, {
    duration,
    frame: frameCount - 1,
    snap: "frame",
    onUpdate: () =>
      requestAnimationFrame(() =>
        renderFrame({
          canvasRef,
          imageFrames,
          framePosition,
        })
      ),
    ease,
  });
};

export {
  applyHighlightAnimation,
  applyFadeInAnimation,
  applyParallaxPhoto,
  textTween,
  getSequenceTween,
};
